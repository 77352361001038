import { useStripe } from "@stripe/react-stripe-js";
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import {
    Checkbox
} from "baseui/checkbox";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import { Tab, Tabs } from "baseui/tabs-motion";
import { PLACEMENT, ToasterContainer, toaster } from "baseui/toast";
import { H2, HeadingSmall, Label1 } from 'baseui/typography';
import 'material-icons/iconfont/filled.css';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toggleSync } from '../../actions/ui';
import { setClaims } from "../../actions/user";
import { trellisLogo } from '../../assets';
import withStripe from "../../billing/withStripe";
import BillingAPI from "../../firebase/BillingAPI";
import Firebase from "../../firebase/Firebase";
import UserApi from "../../firebase/UserApi";
import { ModalOverride, ToasterContainerOverride } from "../../theming";
import { API_CARD_STYLING } from "../Assess/styles";
import { Spacer } from "../layout/Spacer";
import { ROUTE_ONBOARD } from "../navigation/Sidebar";
import { APIPurchasesComponent } from "./APIPurchasesComponent";
import ChangePasswordModal from "./ChangePasswordModal";
import { updateLicenseAndSetLocal } from "./DeviceManager";
import { LicenseTable } from "./LicenseTab";


export const dcalcBlueButton = { Root: { style: { width: '100%' } }, BaseButton: { style: { backgroundColor: '#1E5299', color: 'white', ':hover': { backgroundColor: 'whitesmoke', color: 'black' } } } };
const APIWarningBanner = (props) => {
    let { orgData } = props;
    if (!orgData) orgData = {};
    if (!(orgData.apiQuotaUsed && orgData.apiQuotaUsed && Number(orgData.apiQuotaUsed) / Number(orgData.apiQuotaPurchased) > .9)) return <></>
    return <div style={{ width: '100%', padding: 10, minHeight: '40px', top: '0px', position: 'sticky', backgroundColor: '#924444' }}>
        <Label1 color="white">You are running low on API Requests! Purchase more today to avoid running out!</Label1>
    </div>
}

export const AccountPage = ({ sync, toggleSync, orgData, userData, claims }) => {
    const [whichPurchaseModalOpen, setWhichPurchaseModalOpen] = React.useState();
    const [numLicensesToBuy, setNumLicensesToBuy] = React.useState('');
    const [numLicensesToBuyError, setNumLicensesToBuyError] = React.useState();
    const [portalLoading, setPortalLoading] = React.useState(false);
    const [licenses, setLicenses] = React.useState([]);
    const [showLicense, setShowLicense] = React.useState(false);
    const [activeKey, setActiveKey] = React.useState("0");
    const [passwordResetModalOpen, setPasswordResetModalOpen] = React.useState(false);
    const [changeSubscriptionModalOpen, setChangeSubscriptionModalOpen] = React.useState(false);
    const [stripePageLoading, setStripePageLoading] = React.useState(false);
    const history = useHistory();

    React.useState(() => {
        UserApi.getLicenses(claims.orgId).then(e => setLicenses(e)).catch(e => {
            console.error(e)
            setLicenses([]);
        });
    }, [])

    const stripe = useStripe();

    function buyIt() {
        if (!numLicensesToBuy) {
            setNumLicensesToBuyError('Please enter a quantity');
        } else if (numLicensesToBuy > 50) {
            setNumLicensesToBuyError('Cannot buy more than 50 licenses at a time')
        } else {
            BillingAPI.purchase(stripe, BillingAPI.getBillingType(orgData).key, numLicensesToBuy).then(() => setStripePageLoading(false)).catch(() => setStripePageLoading(false));
            setStripePageLoading(true);
        }
    }

    async function registerMyself(claims) {
        try {
            let license = await UserApi.createLicense();
            let lId = license?.license?.licenseId;
            let newClaims = await UserApi.registerLicense(lId, claims.orgId)
            setClaims(newClaims)

            await updateLicenseAndSetLocal({ lId }, 'device1')

            toaster.positive('Successfully registered')
        } catch (err) {
            toaster.negative('Failed to register user.')
        }
    }

    function openPortal() {
        if (!BillingAPI.getStripeLicensesString()) {
            history.push(ROUTE_ONBOARD);
        } else {
            setPortalLoading(true)
            BillingAPI.goToPortal().then(e => setPortalLoading(false)).catch(e => setPortalLoading(false));
        }
    }

    function buyItModal() {
        let header = whichPurchaseModalOpen === 'seats' ? 'Purchase More Seats' : 'Purchase More API Requests';

        return <Modal overrides={ModalOverride} isOpen={whichPurchaseModalOpen !== undefined} onClose={(e) => setWhichPurchaseModalOpen()} closeable>
            <ModalHeader>{header}</ModalHeader>
            <ModalBody>
                <FormControl error={numLicensesToBuyError}>
                    <Input
                        type='number'
                        value={numLicensesToBuy}
                        onChange={(e) => setNumLicensesToBuy(e.target.value)}
                        placeholder="Num Licenses"
                    ></Input>
                </FormControl>
            </ModalBody>
            <ModalFooter>
                <ModalButton
                    onClick={(e) => setWhichPurchaseModalOpen()}
                    kind={KIND.tertiary}
                >
                    Cancel
                </ModalButton>
                <ModalButton
                    onClick={(e) => buyIt()}
                    isLoading={stripePageLoading}
                    overrides={{ BaseButton: { style: { backgroundColor: '#2596DE', color: 'white', ':hover': { backgroundColor: '#4FB1F7' } } } }}
                >
                    Purchase
                </ModalButton>
            </ModalFooter>
        </Modal>
    }


    const SettingUITab = () => {
        return <Tab title="Settings">
            <H2>Settings</H2>
            <Label1>Licensed to: {userData.firstName + ' ' + userData.lastName} for {orgData.name}</Label1>
            <Spacer />
            <Checkbox
                checked={sync}
                checkmarkType={'toggle_round'}
                onChange={toggleSync}
                labelPlacement={'right'}
                overrides={{
                    ToggleTrack: {
                        style: ({ $theme }) => ({
                            backgroundColor: sync
                                ? $theme.colors.positive200
                                : $theme.colors.negative300
                        })
                    }
                }}>Sync to database</Checkbox>
            <Spacer />
            <InfoUI />
            <Block height='36px' />
        </Tab>
    }


    function billingSettingUI() {
        return <Tab title="Billing">
            <Modal overrides={ModalOverride} isOpen={changeSubscriptionModalOpen} onClose={() => setChangeSubscriptionModalOpen(false)}>
                <ModalHeader>Change Subscription</ModalHeader>
                <ModalBody>Current Billing Frequency: {BillingAPI.getBillingType(orgData).label}</ModalBody>
            </Modal>
            <HeadingSmall>Billing</HeadingSmall>
            {
                (BillingAPI.getHasPurchasedLicenses(orgData)) ?
                    <>
                        <Label1>Licenses: {BillingAPI.getNumLicensesString(orgData)}</Label1>
                    </> : undefined
            }
            <Spacer />
            <Spacer />
            {
                claims.lId ? undefined :
                    <FormControl >
                        <Button onClick={() => registerMyself(claims)} overrides={dcalcBlueButton}>Register Myself</Button>
                    </FormControl>
            }
            <FormControl error={''}>
                <Button onClick={() => openPortal()} isLoading={portalLoading} overrides={dcalcBlueButton}>Change Billing Details</Button>
            </FormControl>
            {showLicense ? <Label1>License Key: {showLicense}</Label1> : undefined}
            {
                BillingAPI.getBillingType(orgData).key === 'perpetualLicenses' ?
                    <Button onClick={(e) => setWhichPurchaseModalOpen('seats')} overrides={dcalcBlueButton}>Buy More seats</Button> : undefined
            }
        </Tab>
    }

    function InfoUI() {
        const [isOpen, setOpen] = React.useState(false);
        return <>
            <Button onClick={() => setOpen(s => !s)} overrides={dcalcBlueButton}>Version Info</Button>
            <div style={{ padding: '30px 0px 30px 0px' }}><a href="https://help.dcalc.edmlink.com/" style={{ color: 'black' }}>Documentation / Get Help</a></div>
            <Modal overrides={ModalOverride} onClose={() => setOpen(false)} isOpen={isOpen}>
                <ModalHeader>Build Version: 5.11</ModalHeader>
                <Block
                    style={
                        {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            flexWrap: 'wrap'
                        }
                    }
                >
                    <ModalBody style={{ fontSize: '1rem' }}>
                        Developed / Designed By:
                    </ModalBody>
                    <img src={trellisLogo} length="42px" height="40px" alt='TrellisSoftware.io logo' />
                </Block>
                <ModalFooter>
                    <ModalButton autoFocus onClick={() => setOpen(false)}>
                        Done
                    </ModalButton>
                </ModalFooter>
            </Modal>
        </>
    }

    const AccountTab = () => {
        return <Tab title="Account">
            <HeadingSmall>Account</HeadingSmall>
            <FormControl label="Email Address">
                <Input value={claims.email} disabled={true}></Input>
            </FormControl>
            <Button overrides={dcalcBlueButton} onClick={(e) => setPasswordResetModalOpen(true)}>Change Password</Button><Spacer />
            <Button onClick={(e) => Firebase.signOut()} overrides={dcalcBlueButton}>Logout</Button>
            <ChangePasswordModal setOpen={setPasswordResetModalOpen} isOpen={passwordResetModalOpen} />
        </Tab>
    }

    return <Block width='80vw' maxWidth="100%" padding='2rem'>
        <APIWarningBanner orgData={orgData} />
        <Tabs
            activeKey={activeKey}
            onChange={({ activeKey }) => setActiveKey(activeKey)}
        >
            {AccountTab()}
            {claims.licensed ? SettingUITab() : undefined}
            {claims.admin ? billingSettingUI() : undefined}
            {claims.admin ?  <Tab title="API"><div style={API_CARD_STYLING}><APIPurchasesComponent orgData={orgData} orgId={claims.orgId} /></div></Tab> : undefined}
            {claims.admin ? <Tab title="Licenses"><LicenseTable licenses={licenses} setLicenses={setLicenses} orgData={orgData} claims={claims} /></Tab> : undefined}
        </Tabs>

        <FlexGrid flexGridColumnCount={1}>
            <FlexGridItem>
            </FlexGridItem>
        </FlexGrid>
        {buyItModal()}
        <ToasterContainer overrides={ToasterContainerOverride} placement={PLACEMENT.bottomLeft} autoHideDuration={5000} />
    </Block >
}

export default connect(state => ({
    sync: state.ui.sync,
    orgData: state.user.orgData,
    claims: state.user.claims,
    userData: state.user.userData,
}), { toggleSync, setClaims })(withStripe(AccountPage));

