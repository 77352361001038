import { toaster } from "baseui/toast";
import React from 'react';
import { connect } from "react-redux";
import { setPoleDetails } from "../../actions/user";
import SyncClientDB from "../database/SyncClientDB";
function CreatePole({ returnURLData, setPoleDetails }) {
  const idb = SyncClientDB.getIDB();
  React.useEffect(async () => {
    let searchParams = new URLSearchParams(new URL(returnURLData).search);
    const diameterParams = searchParams.get('diameter');
    const poleIdParams = searchParams.get('poleId');
    const returnUrlParams = searchParams.get('returnUrl');
    const projectName = searchParams.get('projectName');
    const description = searchParams.get('description');
    const DistGL = searchParams.get('DistGL');
    const Circumference = searchParams.get('Circumference');
    const Species = searchParams.get('Species');
    const Class = searchParams.get('Class');
    const Length = searchParams.get('Length');
    const poleName = searchParams.get('poleName');
    let projs = await (idb).projects.where("deleted").equals("0").toArray();
    let filteredProjectwithId = projs.filter((item, index) => item.name === projectName);
    if (filteredProjectwithId.length === 0) {
      setPoleDetails({ diameterParams, poleIdParams, returnUrlParams, DistGL, Circumference, description, Species, Class, Length, projectName, poleName })
      toaster.warning("Warning: Passed in project name does not match any projects.");
    } else if (filteredProjectwithId.length > 1) {
      setPoleDetails({ diameterParams, poleIdParams, returnUrlParams, DistGL, Circumference, description, Species, Class, Length, projectName, poleName })
      toaster.warning("Warning: Two or more projects with the given name exist. Please choose one...");
    } else if (filteredProjectwithId.length === 1) {
      setPoleDetails({ diameterParams, poleIdParams, returnUrlParams, DistGL, Circumference, description, Species, Class, Length, projectName, poleName, projectId: filteredProjectwithId[0]?.id })
    }
  }, []);
  return <div></div>;
}

export default connect(
  ({ user }) => ({
    returnURLData: user.returnURL.url,
  }),
  {
    setPoleDetails
  }
)(CreatePole);