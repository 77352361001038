import { toaster } from "baseui/toast";
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { viewPole } from '../../actions/poles';
import SyncClientDB from "../database/SyncClientDB";
function ViewPole({ returnURLData, viewPole }) {
  const idb = SyncClientDB.getIDB();
  useEffect(async () => {
    let searchParams = new URLSearchParams(new URL(returnURLData).search);
    const poleIdParams = searchParams.get('poleId');
    const projectName = searchParams.get('projectName');

    // Get all the poles from the db and filtering the pole Id
    let poles = await (idb).poles.where("deleted").equals("0").toArray();
    let getTheSpecificPolePassed = poles.filter((item, index) => item.id === poleIdParams);

    // Get all the project from the db and filter the project with name
    let projs = await (idb).projects.where("deleted").equals("0").toArray();
    let filteredProjectwithId = projs.filter((item, index) => item.name === projectName);
    if (getTheSpecificPolePassed.length === 0) {
      toaster.warning("Pole Not Found.");
    } else if (filteredProjectwithId.length === 0) {
      toaster.warning("Project with given name not found.");
    } else {
      viewPole(...getTheSpecificPolePassed, projectName, filteredProjectwithId[0]?.id);
    }
  }, [])
  return <div></div>;
}

export default connect(
  ({ user }) => ({
    returnURLData: user.returnURL.url,
  }),
  {
    viewPole,
  }
)(ViewPole);
